import React from 'react'
import { MyTicketsContainer } from 'tf-checkout-react'
import Head from '../../components/head/index'
import Layout from '../../components/layout/index'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Logo from '../../assets/images/mana_footer.png'
import './style.scss';

setTfCheckoutReactConfigs()

const Orders = () => {  
  return (
    <>
      <Head title='Orders Page' />
      <Layout>
        <div className='my-orders-page'>
        <MyTicketsContainer
          logo={Logo}
          theme='light'
          handleDetailsInfo={(orderId) => {
            if (typeof window !== 'undefined') {
              window.location.assign(`/order/?o=${orderId}`)
            }
          }}
        />
        </div>
      </Layout>
    </>
  )
}

export default Orders
